/* eslint-disable */
export default {
  init() {
    // JavaScript to be fired on all pages
    $(".header__mobile--hamburger").click(function () {
      $(this).toggleClass("menu-toggle");
      $("body").toggleClass("overflow-body");
      $(".header__mobile").toggle("'slide', {direction: 'right' }, 800");
      $("iframe").toggleClass("index-minus");
    });

    $(".search-form-trigger").click(function () {
      $(".search-form-header").slideToggle("medium");
      setTimeout(function () {
        $("#searchform #s").focus();
        $(".search-form-header")
          .find("input")
          .each((i, el) => {
            $(el).val("");
          });
      }, 500);
    });

    $(document).on("click", function (event) {
      if (!($(event.target).closest(".header__top__menu").length > 0)) {
        $(".search-form-header").slideUp("medium", function () {
          $(this).css("display", "none");
        });
        setTimeout(function () {
          $(".search-form-header")
            .find("input")
            .each((i, el) => {
              $(el).val("");
            });
        }, 500);
      }
    });

    $("li.menu-item-has-children").each(function () {
      $(this).append(
        `<span class="sub-menu--toggle"><svg xmlns="http://www.w3.org/2000/svg" fill="none"><path d="M0.5 0.5L5.5 4.5L10.5 0.5" stroke="#CCCCCC"/></svg></span>`
      );
      $(this)
        .find(".sub-menu--toggle")
        .click(function () {
          $(this).parent().find(".sub-menu").slideToggle("medium");
          $(this)
            .parent()
            .find(".sub-menu--toggle")
            .toggleClass("arrow-opened");
        });
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
