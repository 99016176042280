// import external dependencies
/* eslint-disable */
import "jquery";

// Import everything from autoload
import "./autoload/browserdetect.js";

// import local dependencies
import Router from "./util/Router";
import common from "./routes/common";
import home from "./routes/home";
import category from "./routes/category";

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // Category
  category,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
